<template>
  <el-dialog :visible.sync="visible" center :show-close="false">
    <el-row type="flex" justify="center">
      <el-upload
        ref="upload"
        v-loading="loading"
        class="upload-demo"
        drag
        action="#"
        :file-list="fileList"
        :on-change="onChange"
        :on-remove="onRemove"
        :auto-upload="false"
      >
        <i class="el-icon-takeaway-box upload-icon" />
        <div class="el-upload__text">点击或将文件拖拽到这里上传</div>
      </el-upload>
    </el-row>
    <div slot="footer">
      <el-button
        type="primary"
        :disabled="loading"
        @click="handleSubmit(titleType)"
      >
        提 交
      </el-button>
      <el-button @click="handleCancel"> 取 消 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ImportList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    titleType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileList: [], // 导入文件列表
      loading: false, // 遮罩
    };
  },
  computed: {},
  methods: {
    // 选中文件
    onChange(file, fileList) {
      const fileType = file.raw.type;
      if (
        fileType !== 'application/vnd.ms-excel' &&
        fileType !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.$message.error('文件上传只能是excel文件');
        fileList.pop();
        return;
      } else if (file.size / 1024 / 1024 > 4) {
        this.$message.error('文件大小不能超过4MB.');
        fileList.pop();
        return;
      }
      console.log('file', file, fileList);
      if (this.fileList.length > 0) {
        this.$message.error('最多上传一份文件');
        fileList.pop();
        return;
      }
      this.fileList.push(file);
    },
    // 移除文件
    onRemove(file, fileList) {
      console.log('file', file, fileList);
      this.fileList = [];
    },
    // 显示上传弹窗
    showNotify() {
      this.$notify({
        type: 'success',
        title: '文件已导入完成',
        dangerouslyUseHTMLString: true,
        message: this.renderNotification(),
      });
    },
    // 渲染通知内容
    renderNotification() {
      return '<div><p>后台进行中的匹配任务，进度完成</p></div>';
    },
    // 提交
    handleSubmit(index) {
      this.loading = true;
      console.log(index, 'ppppppp');
      console.log('提交');
      console.log('this.fileList', this.fileList);
      if (this.fileList.length === 0) {
        this.$message.error('请选择文件');
        return;
      }
      const fileForm = new FormData();
      fileForm.append('file', this.fileList[0].raw);
      switch (index) {
        case 'whiteList':
          this.importWhiteList(fileForm);
          break;
        case 'drugDic':
          this.importDrugList(fileForm);
          break;
        case 'basicDic':
          this.importBasicList(fileForm);
      }
    },
    // 取消
    handleCancel() {
      this.$emit('cancel');
    },
    // 白名单接口调用
    importWhiteList(fileForm) {
      this.$api.importWhite(fileForm).then((res) => {
        this.publicTip(res);
      });
    },
    // 药品字典导入
    importDrugList(fileForm) {
      this.$api.importMedical(fileForm).then((res) => {
        this.publicTip(res);
      });
    },
    // 基础字典导入
    importBasicList(fileForm) {
      this.$api.importBase(fileForm).then((res) => {
        this.publicTip(res);
      });
    },
    // 导入成功后的提示公共方法
    publicTip(res) {
      if (res.code === 0) {
        this.loading = false;
        this.$emit('refreshList', 'success');
        this.showNotify();
      } else {
        this.loading = false;
        this.$notify({
          type: 'error',
          title: res.message,
        });
        this.$emit('refreshList', 'failture');
      }
    },
  },
};
</script>

<style scoped>
.upload-icon {
  margin: 30px;
  font-size: 50px;
  color: #1890ff;
}
</style>

<style>
.export-list {
  float: right;
  padding: 7px 15px 7px 15px;
  margin-top: 10px;
  color: #fff;
  background: #1890ff;
  border: none;
  border-radius: 5px;
}
</style>
