<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="100px"
    >
      <el-form-item label="药品编码：">
        <el-input
          v-model.trim="formInline.medicalCode"
          v-check-permission
          placeholder="请输入药品编码"
        />
      </el-form-item>

      <el-form-item label="中西药标记：">
        <el-select v-model="formInline.medicalType">
          <el-option
            v-for="item in symbolList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="药品名称：">
        <el-input
          v-model="formInline.medicalName"
          v-check-permission
          placeholder="请输入药品名称"
        />
      </el-form-item>

      <el-form-item label="状态：">
        <el-select v-model="formInline.status">
          <el-option
            v-for="item in drugStatusList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button
          v-if="$checkPermission('IMPORTDATA', $route)"
          type="primary"
          @click="importData()"
        >
          导入
        </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('DOWNLADTEMPLATE', $route)"
          type="primary"
          @click="downloadTemplate()"
        >
          下载模板
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="药品编码" prop="medicalCode" align="center" />
      <el-table-column
        prop="medicalName"
        label="药品名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="commonCode"
        label="药品名通用编码"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column prop="commonName" label="药品通用名" align="center" />
      <el-table-column
        prop="specsPacking"
        label="规格包装"
        align="center"
        width="100"
      />
      <el-table-column prop="minPackingUnit" label="包装单位" align="center" />
      <el-table-column
        prop="productionName"
        label="生产厂家"
        align="center"
        show-overflow-tooltip
        width="100"
      />
      <!-- <el-table-column
        prop="pocketProportion"
        label="分类自负比例"
        align="center"
      /> -->
      <el-table-column prop="symbol" label="甲乙类标志" align="center" />
      <!-- <el-table-column
        prop="orderNum"
        label="目录顺序"
        align="center"
      /> -->
      <el-table-column prop="retailPrice" label="单价(零售价)" align="center" />
      <el-table-column prop="purchasePrice" label="进价" align="center" />
      <!-- <el-table-column
        prop="useInstructions"
        label="限制使用说明"
        width="100"
        align="center"
      /> -->
      <!-- <el-table-column
        prop="medicalType"
        label="中西药标记"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ symbolDic[scope.row.medicalType] }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status ? '有效' : '无效' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="$checkPermission('EDIT', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
          <el-button
            v-if="!scope.row.status && $checkPermission('VALID', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            有效
          </el-button>
          <el-button
            v-if="scope.row.status && $checkPermission('INVALID', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 3)"
          >
            无效
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="提示" :visible.sync="refundDialogVisible" width="30%">
      <el-form
        v-if="refundDialogVisible"
        ref="form1"
        :model="form1"
        :rules="rules"
        label-width="110px"
        @submit.native.prevent
      >
        <h3 style="margin-bottom: 20px; text-align: center">
          确认将该药品目录置为无效状态吗？
        </h3>
        <!-- <h4>确定{{ orderNo }}订单退诊吗？</h4> -->
        <el-form-item label="原因:" prop="reason">
          <el-input
            v-model="form1.reason"
            v-check-permission
            placeholder="请输入原因"
            style="width: 80%"
            maxlength="100"
            @keyup.enter.native="saveInvalid()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInvalid()">确定</el-button>
      </span>
    </el-dialog>
    <importList
      v-if="importDialogVisible"
      :visible="importDialogVisible"
      :title-type="drugDic"
      @cancel="importDialogVisible = false"
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  drugStatusList,
  symbolList,
  symbolDic,
} from '@/utils/component/publicList';
import importList from '@/views/modules/doctorOms/doctorManage/doctorList/component/importData'; // 导入清单弹窗
export default {
  name: 'ServiceManageList',
  components: {
    importList,
  },
  data() {
    return {
      drugStatusList: drugStatusList, // 药品目录
      symbolList: symbolList, // 中西药标记
      symbolDic: symbolDic, // 中成药字典
      loading: false,
      importDialogVisible: false,
      drugDic: 'drugDic', // 导入
      medicalCode: '', // 药品编码
      refundDialogVisible: false, // 药品无效弹框显示与否
      formInline: {
        medicalType: '',
        medicalCode: '',
        medicalName: '',
        status: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
      form1: {
        reason: '',
      },
      rules: {
        reason: [
          { required: true, message: '请输入药品无效原因', trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      consultTypeAllList: (state) => state.consultTypeAllList,
    }),
    ...mapState('categoryManage', {
      categoryList: (state) => state.categoryList,
    }),
  },
  mounted() {
    this.getDrugList();
  },
  methods: {
    ...mapActions('basicDictionary', [
      'drugPageQuery',
      'drugDisable',
      'drugEnable',
      'importMedical',
    ]),
    // 导入数据
    importData() {
      this.importDialogVisible = true;
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getDrugList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getDrugList();
    },
    // 刷新列表
    refreshList(value) {
      if (value === 'success') {
        this.pageInfo.page = 1;
        this.getDrugList();
      }
      this.importDialogVisible = false;
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        medicalType: '',
        medicalCode: '',
        medicalName: '',
        status: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getDrugList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getDrugList();
    },
    // 分页查询用户套餐列表
    getDrugList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.drugPageQuery(params)
        .then((res) => {
          this.loading = false;
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.medicalCode = item.medicalCode;
      switch (type) {
        case 2:
          this.drugValid();
          break;
        case 3:
          this.refundDialogVisible = true;
          break;
        default:
          this.$router.push({
            path: '/basicDictionary/drugDetail',
            query: {
              type: type,
              drug: JSON.stringify(item),
            },
          });
      }
    },
    drugValid() {
      const param = {
        medicalCode: this.medicalCode,
      };
      this.drugEnable(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('药品启用成功');
          this.getDrugList();
        }
      });
    },
    // 确定退诊
    saveInvalid() {
      const param = {
        medicalCode: this.medicalCode,
        reason: this.form1.reason,
      };
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          this.drugDisable(param).then((res) => {
            if (res.code === 0) {
              this.refundDialogVisible = false;
              this.form1.reason = '';
              this.$message.success('禁用药品成功');
              this.getDrugList();
            }
          });
        } else {
          return false;
        }
      });
    },
    downloadTemplate() {
      var url =
        'https://manniudoctor.oss-cn-shanghai.aliyuncs.com/model/medical.xlsx';
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
  margin-left: 25px;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
