import { render, staticRenderFns } from "./importData.vue?vue&type=template&id=530e4828&scoped=true"
import script from "./importData.vue?vue&type=script&lang=js"
export * from "./importData.vue?vue&type=script&lang=js"
import style0 from "./importData.vue?vue&type=style&index=0&id=530e4828&prod&scoped=true&lang=css"
import style1 from "./importData.vue?vue&type=style&index=1&id=530e4828&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530e4828",
  null
  
)

export default component.exports